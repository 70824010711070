import axios, { AxiosProgressEvent } from 'axios';

interface IResponse {
  status: boolean;
  data: any;
}

export const sendRequest = async (
  data: any,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  url: string,
): Promise<IResponse> => {
  try {
    const Authorization = localStorage.getItem('__token')
      ? {
          Authorization: `Bearer ${localStorage.getItem('__token')}`,
        }
      : {};
    const request = {
      headers: { Accept: 'application/json', ...Authorization },
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
    };
    Object.assign(request, method === 'GET' ? { params: data } : { data });
    const response = await axios(request);

    if (response.status >= 200 && response.status < 299) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: response.data };
    }
  } catch (error: any) {
    return { status: false, data: error?.response?.data };
  }
};

export const sendMultiPart = async (
  data: any,
  method: 'GET' | 'POST' | 'DELETE' | 'PUT',
  url: string,
  onUploadProgress?: (event: AxiosProgressEvent) => void,
): Promise<IResponse> => {
  try {
    const Authorization = localStorage.getItem('__token')
      ? {
          Authorization: `Bearer ${localStorage.getItem('__token')}`,
        }
      : {};
    const request = {
      headers: {
        Accept: '*',
        ...Authorization,
        'Content-Type': 'multipart/form-data',
      },
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
    };
    Object.assign(request, method === 'GET' ? { params: data } : { data });
    const response = await axios({ ...request, onUploadProgress });

    if (response.status >= 200 && response.status < 299) {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: response.data };
    }
  } catch (error: any) {
    return { status: false, data: error?.response?.data };
  }
};
