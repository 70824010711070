import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderCompound from '../../compounds/HeaderCompound';
import { RootState } from '../../store/rootReducer';
import { Button, Checkbox, Input, Modal, Select, Tag } from 'antd';
import { sendRequest } from '../../lib/api';
import { authLoadMe } from '../../store/auth/auth.actions';
import './styles.css';

const SettingsPage = () => {
  const dispatch = useDispatch<any>();

  const profile = useSelector((state: RootState) => state.auth.profile);
  const options = useSelector((state: RootState) => {
    const utils = state.utils;
    let result = utils.alumni_clubs?.map(x => x.name) ?? [];
    result.push(...utils.associations.map(x => x.name));
    result.push(...(utils.athletes?.map(x => x.name) ?? []));
    result.push(...(utils.cities?.map(x => x.name) ?? []));
    result.push(...(utils.countries?.map(x => x.name) ?? []));
    result.push(...(utils.degrees.map(x => x.name) ?? []));
    result.push(...(utils.grad_levels.map(x => x.name) ?? []));
    result.push(...(utils.hobbies.map(x => x.name) ?? []));
    result.push(...(utils.ibc_companies.map(x => x.name) ?? []));
    result.push(...(utils.industries.map(x => x.name) ?? []));
    result.push(...(utils.job_titles.map(x => x.name) ?? []));
    result.push(...(utils.military_branches.map(x => x.name) ?? []));
    result.push(...(utils.org_jobs?.map(x => x.name) ?? []));
    result.push(...(utils.romances?.map(x => x.name) ?? []));
    result.push(...(utils.schools?.map(x => x.name) ?? []));
    result.push(...(utils.skills?.map(x => x.name) ?? []));
    result.push(...(utils.sports_watches?.map(x => x.name) ?? []));
    result.push(...(utils.student_orgs?.map(x => x.name) ?? []));
    result = result.filter(
      (value, index, self) => self.indexOf(value) === index,
    );
    return result;
  });

  const [conCnt, setConCnt] = useState(profile?.connections_size);
  const [showAdd, setShowAdd] = useState(false);
  const [items, setItems] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(false);
  const [emailTraits, setEmailTraits] = useState(profile?.email_traits ?? []);

  const updateCnt = async () => {
    if (conCnt === undefined) {
      window.alert('Please enter shared traits count correctly');
      return;
    }
    setDisabled(true);
    const res = await sendRequest(
      { connections_size: conCnt },
      'POST',
      'api/update-settings',
    );
    if (res.status) {
      await dispatch(authLoadMe());
      setDisabled(false);
    } else {
      setDisabled(false);
      alert(res.data?.message ?? 'Error');
    }
  };
  const addRuleConfirm = async () => {
    if (!items || items.length <= 0) {
      window.alert('Empty data is not allowed');
      return;
    }
    setDisabled(true);
    const data = profile?.settings
      ? [...profile.settings, { traits: items }]
      : [{ traits: items }];
    const res = await sendRequest(
      { settings: data },
      'POST',
      'api/update-settings',
    );
    if (res.status) {
      await dispatch(authLoadMe());
      setDisabled(false);
      setItems([]);
      setShowAdd(false);
    } else {
      window.alert(res.data?.message ?? 'Error');
    }
  };
  const deleteRule = async (i: number) => {
    setDisabled(true);
    let settings = [...(profile?.settings ?? [])];
    settings.splice(i, 1);
    const res = await sendRequest({ settings }, 'POST', 'api/update-settings');
    if (res.status) {
      await dispatch(authLoadMe());
      setDisabled(false);
    } else {
      setDisabled(false);
      alert(res.data?.message ?? 'Error');
    }
  };
  const togglePrivateCheck = async (c: boolean) => {
    setDisabled(true);
    const res = await sendRequest(
      { email_is_private: c },
      'POST',
      'api/update-settings',
    );
    if (res.status) {
      await dispatch(authLoadMe());
      setDisabled(false);
    } else {
      window.alert(res.data?.message ?? 'Unknown error');
      setDisabled(false);
    }
  };
  const updateEmailTraits = async () => {
    setDisabled(true);
    const res = await sendRequest(
      { email_traits: emailTraits },
      'POST',
      'api/update-settings',
    );
    if (res.status) {
      await dispatch(authLoadMe());
      setDisabled(false);
    } else {
      window.alert(res.data?.message ?? 'Unknown error');
      setDisabled(false);
    }
  };

  return (
    <div>
      <HeaderCompound hideFeature={true} />
      <div className='settings-body'>
        <div className='setting-section'>
          <label>
            Type in the number of matching traits or more you want in order to
            receive an email about a match when a new alumni joins, yOUrs is
            currently set to:
          </label>
          <Input
            type='number'
            value={conCnt?.toString() ?? ''}
            onChange={({ target }) =>
              setConCnt(
                target.value.length <= 0 ? undefined : Number(target.value),
              )
            }
            disabled={disabled}
            style={{ marginTop: 10 }}
          />
          <div className='mt-10'>
            <Button type='primary' disabled={disabled} onClick={updateCnt}>
              Update
            </Button>
          </div>
        </div>
        <div className='setting-section'>
          <div className='mb-20'>
            <Button
              type='primary'
              onClick={() => setShowAdd(true)}
              disabled={disabled}
              className='mb-10'
            >
              Create a NEW email rule
            </Button>
            {profile?.settings?.map((t, i) => (
              <div className='mb-10' key={i}>
                <div className='d-flex'>
                  {t.traits.map((x, j) => (
                    <Tag key={j}>{x}</Tag>
                  ))}
                </div>
                <Button
                  danger
                  size='small'
                  disabled={disabled}
                  onClick={() => deleteRule(i)}
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
        </div>
        <div className='setting-section mb-20'>
          <div className='mb-20'>
            <label>
              MAKE your email private by checking below: (note you can select
              traits or tribes which can view your email){' '}
            </label>
            <Checkbox
              checked={profile?.email_is_private}
              onChange={c => togglePrivateCheck(c.target.checked)}
              disabled={disabled}
            />
          </div>
          {profile?.email_is_private && (
            <div>
              <div className='mb-10'>
                <div>
                  {`My email is ${
                    profile?.email_is_private ? 'public' : 'private'
                  } for:`}
                </div>
                <Select
                  style={{ width: '100%' }}
                  options={options.map(x => ({ label: x, value: x }))}
                  value={emailTraits}
                  onChange={v => setEmailTraits(v)}
                  allowClear={true}
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                  mode='multiple'
                />
              </div>
              <div className='d-flex justify-end'>
                <Button
                  type='primary'
                  disabled={disabled}
                  onClick={updateEmailTraits}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showAdd && (
        <Modal
          title='Create a new rule'
          width={600}
          onCancel={() => setShowAdd(false)}
          footer={null}
          open={true}
        >
          <div className='w-100 mb-20'>
            <div>Select traits</div>
            <Select
              style={{ width: '100%' }}
              options={options.map(x => ({ label: x, value: x }))}
              value={items}
              onChange={v => setItems(v)}
              allowClear={true}
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              mode='multiple'
            />
          </div>
          <div className='d-flex justify-end'>
            <Button type='primary' disabled={disabled} onClick={addRuleConfirm}>
              Confirm
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SettingsPage;
