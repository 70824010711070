import React, { ReactNode } from 'react'
import styled from 'styled-components'

/*---> Component <---*/
const HeaderWrapper = ({ children, wrapClass }: PropsType) => {
  return (
    <Wrapper id='header' className={wrapClass || ''}>
      {children}
    </Wrapper>
  )
}

/*---> Styles <---*/
export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #b71e24;
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;

  &.lg {
    // height: 84vh;
  }
  
  &.md {
    // height: 400px;
  }

  &.sm {
    // height: 240px;
  }
`

/*---> Interfaces <---*/
interface PropsType {
  children?: ReactNode;
  wrapClass?: string;
}

export default HeaderWrapper
