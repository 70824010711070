import React from 'react'
import styled from 'styled-components'

/*---> Component <---*/
const Seperator = () => {
  return <Wrapper />
}

/*---> Styles <---*/
export const Wrapper = styled.div`
border-top: 3px solid #fffee3; /* Only a top border to act as a separator */
width: 50%;
margin: 30px auto; /* Increased margin for more space around the separator */
border-radius: 5px; /* Rounded corners */
box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
background-image: linear-gradient(to right, #fffee3, #b71e24); /* Gradient from left to right */
`

export default Seperator
