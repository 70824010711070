import { Avatar, Button, Modal } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { compareTraits } from '../lib/traits';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import { IUserProfile } from '../lib/entities';
import { useState } from 'react';
import ReviewFormModal from './ReviewFormModal';
import './style.css';
import { sendRequest } from '../lib/api';

const TopWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0px;
  background-image: url('/images/misc/home-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 12px;
  border-radius: 8px;
`;

const AvatarWrapper = styled.div`
  border: 3px solid #fff;
  border-radius: 50%;
  box-shadow: 0 4px 5px 2px rgb(0 0 0 / 22%);
  margin: 0 12px;
  width: fit-content;
  cursor: pointer;
`;

const TraitWrap = styled.div`
  flex: 1;
  text-align: center;
  font-weight: bold;
  color: #fff;
  & .shared {
    font-size: 40px;
  }
`;

const UserNameWrap = styled.div`
  flex: 1;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  & .user-name {
    font-family: 'Allura';
    font-weight: bold;
    font-size: 28px;
  }
`;

const InfoBox = styled.div`
  position: relative;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 12px 12px 7px 12px;
  margin-top: 32px;

  & .title {
    position: absolute;
    top: -12px;
    left: 12px;
    font-size: 14px;
    color: #8f8f8f;
    font-weight: 600;
    background-color: #fff;
    padding: 0 8px;
  }
`;

const InfoValues = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size; 18px;
  & span {
    display: block;
    margin-right: 5px;
    padding: 4px 8px;
    border: 1px solid gray;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  & .active {
    background-color: #982022;
    color: #fff;
    border-color: #982022;
  }
`;

const AboutMe = styled.div`
  padding: 12px 24px;
  & .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  & .content {
    font-size: 16px;
  }
`;

interface Props {
  title?: string;
  visible: boolean;
  user: IUserProfile;
  onClose: () => void;
  onLoad?: () => Promise<void>;
}

const UserModal = ({
  title,
  visible,
  user: paramUser,
  onClose,
  onLoad,
}: Props) => {
  const profile = useSelector((state: RootState) => state.auth.profile);

  const [user, setUser] = useState(paramUser);
  const [visRev, setVisRev] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const clickUser = async (userID: number) => {
    setDisabled(true);
    const res = await sendRequest({}, 'GET', `api/user/${userID}`);
    setDisabled(false);
    if (res.status) {
      document
        .getElementsByClassName('ant-modal-wrap')[0]
        .scrollTo({ top: 0, behavior: 'smooth' });
      setUser(res.data);
    } else {
      window.alert(res.data.message ?? 'Oh no! Failed to get user info and Texas Sucks');
    }
  };

  return (
    <>
      {!visRev ? (
        <Modal
          title={title}
          onCancel={onClose}
          onOk={onClose}
          open={visible}
          width={700}
          closable={!disabled}
        >
          <TopWrap
            style={
              user?.profile_url
                ? { backgroundImage: `url(${user?.profile_url})` }
                : {}
            }
          >
            <TraitWrap>
              <div className='desc'>Shared Traits:</div>
              <div>
                <span className='shared'>
                  {profile ? Math.round(compareTraits(profile, user)) : 0}
                </span>
              </div>
            </TraitWrap>
            <AvatarWrapper>
              <Avatar
                shape='circle'
                size={290}
                src={user.profile_pic}
                icon={<UserOutlined />}
              />
            </AvatarWrapper>
          </TopWrap>

          <UserNameWrap>
            <div style={{ color: 'white' }}>
              {`${user.first_name} ${user.last_name} ${
                user.nickname ? `(${user.nickname})` : ''
              }`}
            </div>
            {user.email && (
              <div style={{ color: 'yellow' }}>
                <a href={`mailto:${user.email}`}>{user.email}</a>
              </div>
            )}
            {user.linkedin_url && (
              <div style={{ color: 'black' }}>
                <a href={user.linkedin_url} target='_blank' rel='noreferrer'>
                  {user.linkedin_url}
                </a>
              </div>
            )}
          </UserNameWrap>

          {!!user.headline && (
            <AboutMe>
              <div className='title'>Read About Me:</div>
              <div className='content'>{user.headline}</div>
            </AboutMe>
          )}
          {!!user.youtube_video_url && (
            <ReactPlayer width='auto' url={user.youtube_video_url} />
          )}
          <InfoBox>
            <div className='title'>Athletic Games I am Going to Attend:</div>
            <InfoValues>
              {user.sec_games?.map((x, i) => (
                <span
                  key={i}
                  className={profile?.sec_games?.includes(x) ? 'active' : ''}
                >
                  {x}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Single or Married?</div>
            <InfoValues>
              {!!user.romance && (
                <span
                  className={profile?.romance === user.romance ? 'active' : ''}
                >
                  {user.romance}
                </span>
              )}
            </InfoValues>
          </InfoBox>
          {!!user.type && (
            <InfoBox>
              <div className='title'>
                {user.type === 'OWN'
                  ? "I am a CEO, looking for these skills on my team:"
                  : 'I have these skills to offer a Sooner startup:'}
              </div>
              <InfoValues>
                {user.skills?.map((x, i) => (
                  <span
                    key={i}
                    className={
                      profile?.skills?.some(y => y.id === x.id) ? 'active' : ''
                    }
                  >
                    {x.name}
                  </span>
                ))}
              </InfoValues>
            </InfoBox>
          )}
          <InfoBox>
            <div className='title'>When I Graduated:</div>
            <InfoValues>
              {!!user.grad_year && (
                <span
                  className={
                    profile?.grad_year === user.grad_year ? 'active' : ''
                  }
                >
                  {user.grad_year}
                </span>
              )}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Degree or Degrees:</div>
            <InfoValues>
              {user.degrees?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.degrees?.some(y => y.id === x.id) ? 'active' : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Bachelors, Masters, or Phd:</div>
            <InfoValues>
              {user.grad_levels?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.grad_levels?.some(y => y.id === x.id)
                      ? 'active'
                      : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
         
         
          <InfoBox>
            <div className='title'>National Associations I am in:</div>
            <InfoValues>
              {user.associations?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.associations?.some(y => y.id === x.id)
                      ? 'active'
                      : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>My Industries:</div>
            <InfoValues>
              {user.industries?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.industries?.some(y => y.id === x.id)
                      ? 'active'
                      : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>My Hobbies & Interests:</div>
            <InfoValues>
              {user.hobbies?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.hobbies?.some(y => y.id === x.id) ? 'active' : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Military Branch Affiliation:</div>
            <InfoValues>
              <span
                className={
                  profile?.military_branch?.id === user.military_branch?.id
                    ? 'active'
                    : ''
                }
              >
                {user.military_branch?.name}
              </span>
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Cities I Travel to Often:</div>
            <InfoValues>
              {user.cities?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.cities?.some(y => y.id === x.id) ? 'active' : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Countries I Most Associate With:</div>
            <InfoValues>
              {user.countries?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.countries?.some(y => y.id === x.id) ? 'active' : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Job Titles I Have:</div>
            <InfoValues>
              {user.job_titles?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.job_titles?.some(y => y.id === x.id)
                      ? 'active'
                      : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>My High School & Other Colleges:</div>
            <InfoValues>
              {user.schools?.map(x => (
                <span
                  key={x.id}
                  className={
                    profile?.schools?.some(y => y.id === x.id) ? 'active' : ''
                  }
                >
                  {x.name}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
         
          
          
          <InfoBox>
            <div className='title'>OU Alumni Clubs I am Apart of:</div>
            <InfoValues>
              {user.alumni_clubs?.map((x, i) => (
                <span
                  key={i}
                  className={profile?.alumni_clubs?.includes(x) ? 'active' : ''}
                >
                  {x}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Sooner Sports I Follow:</div>
            <InfoValues>
              {user.sports_watches?.map((x, i) => (
                <span
                  key={i}
                  className={
                    profile?.sports_watches?.includes(x) ? 'active' : ''
                  }
                >
                  {x}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Student Athlete or Not?</div>
            <InfoValues>
              {user.athletes && (
                <span
                  className={
                    profile?.athletes?.name === user.athletes.name
                      ? 'active'
                      : ''
                  }
                >
                  {`${user.athletes.name}: ${user.athletes.positions?.join(
                    ', ',
                  )}`}
                </span>
              )}
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>IBC Company I Was In:</div>
            <InfoValues>
              <span
                className={
                  profile?.ibc_company_id === user.ibc_company_id
                    ? 'active'
                    : ''
                }
              >
                {user.ibc_company?.name}
              </span>
            </InfoValues>
          </InfoBox>
          <InfoBox>
            <div className='title'>Past Student Orgs I was In:</div>
            <InfoValues>
              {user.org_job_groups?.map((x, i) => (
                <span
                  key={i}
                  className={
                    profile?.org_job_groups?.includes(x) ? 'active' : ''
                  }
                >
                  {`${x.org.name} - ${x.jobs.map(y => y.name).join(', ')}`}
                </span>
              ))}
            </InfoValues>
          </InfoBox>
          
          
          {!!user.video_url && (
            <div style={{ margin: '30px 10px', textAlign: 'center' }}>
              <video width='500' controls>
                <source src={user.video_url} />
              </video>
            </div>
          )}
          {user.reviews.length > 0 && (
            <div className='reviews-wrapper'>
              <div className='--title'>Reviews About this Sooner</div>
              {user.reviews.map(review => (
                <div key={review.id} className='review-box'>
                  {!!review.meet_content && (
                    <pre className='review-content'>{review.meet_content}</pre>
                  )}
                  {!!review.recommend_content && (
                    <pre className='review-content'>
                      {review.recommend_content}
                    </pre>
                  )}
                  {!!review.memory_content && (
                    <pre className='review-content'>
                      {review.memory_content}
                    </pre>
                  )}
                  <div
                    className='review-writer'
                    style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                    onClick={() => clickUser(review.user.id)}
                  >
                    <span>{`Written by ${review.user.first_name} ${review.user.last_name} on ${review.created_at}`}</span>
                    {!!review.user.profile_pic && (
                      <span>
                        <img src={review.user.profile_pic} alt={'profile'} />
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {user.id !== profile?.id && (
            <div className='mt-20'>
              <Button type='primary' onClick={() => setVisRev(!visRev)}>
                {'Add A Review about this Sooner'}
              </Button>
            </div>
          )}
        </Modal>
      ) : (
        <ReviewFormModal
          visible={true}
          onClose={onClose}
          user={user}
          onLoad={onLoad}
        />
      )}
    </>
  );
};

export default UserModal;
