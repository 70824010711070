import { Dispatch } from 'redux';
import { sendRequest } from '../../lib/api';
import { IAuthAction } from './auth.type';

const setAuth = (auth: IAuthAction): IAuthAction => auth;

export const authLogin = (data: { email: string; password: string }) => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest(data, 'POST', 'api/auth/signin');

    if (res.status) {
      localStorage.setItem('__token', res.data.access_token);
      dispatch(
        setAuth({ type: 'AUTH/SET_TOKEN', payload: res.data.access_token }),
      );
      dispatch(setAuth({ type: 'AUTH/SET_PROFILE', payload: res.data.data }));

      return !!res.data.data.is_verified;
    } else {
      dispatch(setAuth({ type: 'AUTH/LOGOUT' }));
      localStorage.removeItem('__token');

      return res.data.message ?? 'Failed to login';
    }
  };
};

export const authRegister = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest(data, 'POST', 'api/auth/register');

    if (res.status) {
      localStorage.setItem('__token', res.data.access_token);
      dispatch(
        setAuth({ type: 'AUTH/SET_TOKEN', payload: res.data.access_token }),
      );
      dispatch(setAuth({ type: 'AUTH/SET_PROFILE', payload: res.data.data }));

      return true;
    } else {
      dispatch(setAuth({ type: 'AUTH/LOGOUT' }));
      localStorage.removeItem('__token');

      if (res.data.errors) {
        for (let k in res.data.errors) {
          const e = res.data.errors[k];
          return e[0];
        }
      }
      return res.data.message ?? 'Failed to login';
    }
  };
};

export const updateProfile = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest(data, 'POST', 'api/update-profile');

    if (res.status) {
      dispatch(setAuth({ type: 'AUTH/SET_PROFILE', payload: res.data }));

      return true;
    } else {
      return res.data.message ?? 'Failed to login';
    }
  };
};

export const authLoadMe = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/auth/me');

    if (res.status) {
      dispatch(setAuth({ type: 'AUTH/SET_PROFILE', payload: res.data }));

      return true;
    } else {
      return res.data.message ?? 'Failed to login';
    }
  };
};

export const authVerify = (token: string) => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({ token }, 'POST', 'api/auth/verify');
    if (res.status) {
      localStorage.setItem('__token', res.data.access_token);
      dispatch(
        setAuth({ type: 'AUTH/SET_TOKEN', payload: res.data.access_token }),
      );
      dispatch(setAuth({ type: 'AUTH/SET_PROFILE', payload: res.data.data }));

      return true;
    } else {
      return res.data?.message ?? 'Failed to verify';
    }
  };
};

export const authSetOnboardShow = (show: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setAuth({ type: 'AUTH/SET_ONBOARD_SHOW', payload: show }));
  };
};

export const authLogout = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(setAuth({ type: 'AUTH/LOGOUT' }));
    localStorage.removeItem('__token');
  };
};
