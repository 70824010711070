import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderWrapper from '../../components/Header/HeaderWrapper';
import Logo from '../../components/Header/Logo';
import Navbar from '../../components/Header/Navbar';
import SignFormBase from '../../components/SignForm/SignFormBase';
import SignFormWrapper from '../../components/SignForm/SignFormWrapper';
import SignFormTitle from '../../components/SignForm/SignFormTitle';
import SignFormError from '../../components/SignForm/SignFormError';
import SignFormInput from '../../components/SignForm/SignFormInput';
import SignFormButton from '../../components/SignForm/SignFormButton';
import { sendRequest } from '../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import { authVerify } from '../../store/auth/auth.actions';
import SignFormLink from '../../components/SignForm/SignFormLink';
import { RootState } from '../../store/rootReducer';

const VerifyPage = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const query = new URLSearchParams(useLocation().search);
  const token = useSelector((state: RootState) => state.auth.token);

  const [code, setCode] = useState(query.get('token') ?? '');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!code) {
      setError('Please enter verify code');
      return;
    }
    setDisabled(true);
    const res = await dispatch(authVerify(code));
    if (res === true) {
      history.replace('/users');
    } else {
      setDisabled(false);
      setError(res);
    }
  };

  const resendClick = async () => {
    setDisabled(true);
    const res = await sendRequest({}, 'POST', 'api/auth/resend-verify');
    setDisabled(false);
    if (res.status) {
      alert('New code has been sent to your email!');
    } else {
      alert(res.data?.message ?? 'Unknown error');
    }
  };

  useEffect(() => {
    const vt = query.get('token');
    if (vt) {
      (async () => {
        setDisabled(true);
        const res = await dispatch(authVerify(vt));
        if (res === true) {
          history.replace('/users');
        } else {
          setDisabled(false);
          setError(res);
        }
      })();
    }
  }, [dispatch, history, query]); // Added 'query' to the dependency array

  return (
    <>
      <HeaderWrapper>
        <Navbar hideNav={true}>
          <Logo />
        </Navbar>
        <SignFormWrapper>
          <SignFormBase onSubmit={handleSubmit} method='POST'>
            <SignFormTitle>
              Boomer Sooner! Please enter your verify code
            </SignFormTitle>
            {error ? <SignFormError>{error}</SignFormError> : null}
            <SignFormInput
              type='text'
              placeholder='Your verify code:'
              autoComplete='off'
              value={code}
              onChange={({ target }) => setCode(target.value)}
            />
            <SignFormButton disabled={disabled} type='submit'>
              Verify
            </SignFormButton>
            {token && (
              <SignFormLink onClick={resendClick}>Resend code</SignFormLink>
            )}
          </SignFormBase>
        </SignFormWrapper>
      </HeaderWrapper>
    </>
  );
};

export default VerifyPage;
