import { Input, Modal } from 'antd';
import { IUserProfile } from '../lib/entities';
import { useState } from 'react';
import { sendRequest } from '../lib/api';

const { TextArea } = Input;

interface IForm {
  meet_content: string | null;
  recommend_content: string | null;
  memory_content: string | null;
}

interface Props {
  visible: boolean;
  onClose: () => void;
  onLoad?: () => Promise<void>;
  user: IUserProfile;
}

const ReviewFormModal = ({ visible, onClose, onLoad, user }: Props) => {
  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<IForm>({
    meet_content: null,
    recommend_content: null,
    memory_content: null,
  });

  const updateForm = (key: keyof IForm, value: string) =>
    setForm({
      ...form,
      [key]: value.length <= 0 ? null : value,
    });

  const submitReview = async () => {
    if (!form.meet_content && !form.recommend_content && !form.memory_content) {
      window.alert('Please write at least one review');
      return;
    }
    setDisabled(true);
    const res = await sendRequest(
      { ...form, profile_id: user.id },
      'POST',
      'api/user-review',
    );
    if (res.status) {
      window.alert('Thank you, your review has been posted!');
      if (onLoad) {
        await onLoad();
      }
      onClose();
    } else {
      setDisabled(false);
      window.alert(res.data.message ?? 'Failed to submit review');
    }
  };

  return (
    <Modal
      title={`Write review for ${user.first_name}`}
      onCancel={onClose}
      onOk={submitReview}
      open={visible}
      width={700}
      closable={disabled}
    >
      <h4 className='mb-5'>How do you know this Sooner?</h4>
      <TextArea
        placeholder='How do you know this Sooner?'
        value={form.meet_content || ''}
        onChange={e => updateForm('meet_content', e.target.value)}
        rows={5}
        disabled={disabled}
      />
      <h4 className='mt-20 mb-5'>What would you recommend this Sooner for?</h4>
      <TextArea
        placeholder='What would you recommend this Sooner for?'
        value={form.recommend_content || ''}
        onChange={e => updateForm('recommend_content', e.target.value)}
        rows={5}
        disabled={disabled}
      />
      <h4 className='mt-20 mb-5'>
        What is a memory you have with this Sooner?
      </h4>
      <TextArea
        placeholder='What is a memory you have with this Sooner?'
        value={form.memory_content || ''}
        onChange={e => updateForm('memory_content', e.target.value)}
        rows={5}
        disabled={disabled}
      />
    </Modal>
  );
};

export default ReviewFormModal;
