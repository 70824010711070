import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SignFormWrapper from '../../components/SignForm/SignFormWrapper';
import SignFormBase from '../../components/SignForm/SignFormBase';
import SignFormTitle from '../../components/SignForm/SignFormTitle';
import SignFormInput from '../../components/SignForm/SignFormInput';
import SignFormButton from '../../components/SignForm/SignFormButton';
import SignFormText from '../../components/SignForm/SignFormText';
import SignFormLink from '../../components/SignForm/SignFormLink';
import SignFormError from '../../components/SignForm/SignFormError';
import { authLogin } from '../../store/auth/auth.actions';
import { useDispatch } from 'react-redux';
import FooterCompound from '../../compounds/FooterCompound';
import HeaderCompound from '../../compounds/HeaderCompound';



/*---> Component <---*/
const SigninPage = () => {
  const history = useHistory();
  const dispatch = useDispatch<any>();

  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const IsInvalid = password === '' || emailAddress === '';

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const res = await dispatch(authLogin({ email: emailAddress, password }));
    if (res === true) {
      history.replace('/users');
    } else if (res === false) {
      history.replace('/verify');
    } else {
      setError(res);
    }
  };

  return (
    <>

<HeaderCompound hideFeature={true}>
      {/* Rest of your code remains here */}
    </HeaderCompound>
    <SignFormWrapper>
    <SignFormBase onSubmit={handleSubmit} method='POST'>
            <SignFormTitle>
              Boomer Sooner! Welcome Back! Please Sign in below:
            </SignFormTitle>
            {error ? <SignFormError>{error}</SignFormError> : null}
            <SignFormInput
              type='text'
              placeholder='yOUr Email Address:'
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
            />
            <SignFormInput
              type='password'
              placeholder='yOUr Password:'
              autoComplete='off'
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
            <SignFormButton disabled={IsInvalid} type='submit'>
              Sign In
            </SignFormButton>

            <SignFormText>
              <SignFormLink onClick={() => history.push('/forgot-password')}>
                Forgot yOUr password?
              </SignFormLink>
            </SignFormText>
          </SignFormBase>

    </SignFormWrapper>

    
      <FooterCompound></FooterCompound>
    </>
  );
};

export default SigninPage;
