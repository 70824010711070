import HeaderWrapper from '../components/Header/HeaderWrapper';
import Logo from '../components/Header/Logo';
import Navbar from '../components/Header/Navbar';
import FooterCompound from '../compounds/FooterCompound';

const PrivacyPage = () => {
  // Define the inline style
  const privacyPolicyStyle = {
    margin: '20px',
    padding: '20px',
    backgroundColor: '#000',
    borderRadius: '10px',
    lineHeight: '1.6',
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
  };

  return (
    <>
      <HeaderWrapper>
        <Navbar>
          <Logo />
        </Navbar>
        <h1>Privacy Policy for OUAlumni.org</h1>
        <div style={privacyPolicyStyle}>
          <h2>We Care About yOUr Privacy</h2>
          <p className='effective-date'>Effective Date: 12/25/2023</p>
          <p>
            OUAlumni.org ("we," "our," or "us") is committed to protecting your
            privacy. This Privacy Policy explains how we collect, use, and
            safeguard your information when you use our mobile application and
            online community directory for alumni of the University of Oklahoma
            ("OUAlumni.org").
          </p>

          <h3>1. Information We Collect</h3>
          <p>We collect the following personal information from you:</p>
          <ul>
            <li>
              Required Information:** Name, email address, degree, graduation
              year, and profile picture.
            </li>
            <li>
              Optional Information:** Nickname, LinkedIn URL, job title,
              military affiliation, and other optional traits you may choose to
              provide.
            </li>
          </ul>

          <h3>2. How We Use Your Information</h3>
          <p>
            We use your information solely to enhance your experience on
            OUAlumni.org. Specifically, we use your data to:
          </p>
          <ul>
            <li>Match you with other alumni based on shared traits.</li>
            <li>
              Send you notifications about new matches and relevant updates.
            </li>
          </ul>
          <p>
            We do not use your information for marketing purposes, and we never
            share, sell, or disclose your personal data to any third parties.
          </p>

          <h3>3. Data Sharing</h3>
          <p>
            We do not share your personal information with third parties. Your
            data is used exclusively within OUAlumni.org for the purposes
            outlined in this policy.
          </p>

          <h3>4. Data Security</h3>
          <p>
            We prioritize the security of your data and employ the following
            measures:
          </p>
          <ul>
            <li>Full SSL encryption to protect data transmission.</li>
            <li>Secure server hosting on Digital Ocean.</li>
            <li>
              Regular security audits to maintain robust security standards.
            </li>
            <li>
              Our database runs on MySQL and utilizes the latest security
              protocols from Laravel.
            </li>
          </ul>

          <h3>5. User Rights</h3>
          <p>
            You have the following rights regarding your personal information:
          </p>
          <ul>
            <li>
              Access and Edit: You can access and edit your data through your
              account settings.
            </li>
            <li>
              Data Deletion: If you wish to have your data removed, please
              contact us at{' '}
              <a href='mailto:support@oualumni.org'>support@oualumni.org</a>,
              and we will delete your account and associated information.
            </li>
          </ul>

          <h3>Contact Us</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>

          <p>
            Email:{' '}
            <a href='mailto:support@oualumni.org'>support@oualumni.org</a>
          </p>
        </div>
      </HeaderWrapper>
      <FooterCompound />
    </>
  );
};

export default PrivacyPage;
