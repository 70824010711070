import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FooterCompound from '../compounds/FooterCompound';
import HeaderCompound from '../compounds/HeaderCompound';
import { sendRequest } from '../lib/api';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import UserModal from '../compounds/UserModal';
import { compareTraits } from '../lib/traits';
import UserCard from '../components/Card/UsersCard';
import { IUserProfile } from '../lib/entities';
import './styles.css';

interface IUserRow {
  label: string;
  users: IUserProfile[];
}

const UsersList: React.FC<{
  label: string;
  users: IUserProfile[];
  onClick: (d: any) => void;
}> = ({ label, users, onClick }) => (
  <div className='users-list'>
    <div className='label text-allura font-40'>{label}</div>
    <div className='horizontal-bar'>
      {users.map(x => (
        <UserCard key={x.id} user={x} onClick={onClick} />
      ))}
    </div>
  </div>
);

const UsersPage = () => {
  const profile = useSelector((state: RootState) => state.auth.profile);

  const [users, setUsers] = useState<IUserProfile[]>();
  const [openUser, setOpenUser] = useState<IUserProfile>();

  const userRows = useMemo(() => {
    const res: IUserRow[] = [];
    if (!profile) return res;

    if (profile.type !== null) {
      let us = users?.filter(x =>
        x.skills?.some(y => profile.skills?.some(t => t.id === y.id)),
      );
      if (profile.type === 'OWN') {
        us = us?.filter(x => x.type === 'JOIN');
      } else {
        us = us?.filter(x => x.type === 'OWN');
      }
      if (us && us.length > 0) {
        res.push({ label: 'Startup company users', users: us });
      }
    }
    for (let deg of profile?.degrees ?? []) {
      const us = users
        ?.filter(user => user.degrees?.some(x => x.id === deg.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${deg.name} members`, users: us });
      }
    }
    for (let grad of profile?.grad_levels ?? []) {
      const us = users
        ?.filter(user => user.grad_levels?.some(x => x.id === grad.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${grad.name} members`, users: us });
      }
    }
    if (profile.ibc_company) {
      const us = users
        ?.filter(user => user.ibc_company_id === profile.ibc_company_id)
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${profile.ibc_company.name} members`, users: us });
      }
    }
    for (let asc of profile.associations ?? []) {
      const us = users
        ?.filter(user => user.associations?.some(x => x.id === asc.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${asc.name} members`, users: us });
      }
    }
    for (let sc of profile.schools ?? []) {
      const us = users
        ?.filter(user => user.schools?.some(x => x.id === sc.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${sc.name} members`, users: us });
      }
    }
    for (let hb of profile.hobbies ?? []) {
      const us = users
        ?.filter(user => user.hobbies?.some(x => x.id === hb.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${hb.name} members`, users: us });
      }
    }
    for (let ind of profile.industries ?? []) {
      const us = users
        ?.filter(user => user.industries?.some(x => x.id === ind.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${ind.name} members`, users: us });
      }
    }
    for (let job of profile.job_titles ?? []) {
      const us = users
        ?.filter(user => user.job_titles?.some(x => x.id === job.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${job.name} members`, users: us });
      }
    }
    if (profile.military_branch) {
      const us = users
        ?.filter(
          user => user.military_branch?.id === profile.military_branch?.id,
        )
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({
          label: `${profile.military_branch.name} members`,
          users: us,
        });
      }
    }
    for (let city of profile.cities ?? []) {
      const us = users
        ?.filter(user => user.cities?.some(x => x.id === city.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${city.name} members`, users: us });
      }
    }
    for (let cou of profile.countries ?? []) {
      const us = users
        ?.filter(user => user.countries?.some(x => x.id === cou.id))
        .map(x => ({
          ...x,
          share_cnt: compareTraits(profile, x),
        }));
      us?.sort((a, b) => b.share_cnt - a.share_cnt);
      if (us && us.length > 0) {
        res.push({ label: `${cou.name} members`, users: us });
      }
    }
    return res;
  }, [users, profile]);

  const loadData = useCallback(async () => {
    const res = await sendRequest({}, 'GET', 'api/users');
    setUsers(res.data);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <HeaderCompound hideFeature={true} size='md' />
      <div style={{ paddingTop: '32px' }}>
        {userRows.map((x, i) => (
          <UsersList
            key={i}
            label={x.label}
            users={x.users}
            onClick={u => setOpenUser(u)}
          />
        ))}
      </div>
      <FooterCompound />
      {openUser && (
        <UserModal
          visible={true}
          user={openUser}
          onClose={() => setOpenUser(undefined)}
          onLoad={loadData}
        />
      )}
    </>
  );
};

export default UsersPage;
