import { IUserProfile } from '../../lib/entities';
import { IAuthAction } from './auth.type';

interface IAuthState {
  token: string | null;
  profile: IUserProfile | null;
  onboardShow: boolean;
}

const initState: IAuthState = {
  token: null,
  profile: null,
  onboardShow: true,
};

const authReducer = (state = initState, action: IAuthAction): IAuthState => {
  switch (action.type) {
    case 'AUTH/SET_TOKEN': {
      return { ...state, token: action.payload };
    }

    case 'AUTH/SET_PROFILE': {
      return { ...state, profile: action.payload };
    }

    case 'AUTH/SET_ONBOARD_SHOW': {
      return { ...state, onboardShow: action.payload };
    }

    case 'AUTH/LOGOUT': {
      return initState;
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
