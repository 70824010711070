import { combineReducers } from 'redux';
import authReducer from './auth/auth.reducer';
import utilReducer from './util/util.reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  utils: utilReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
