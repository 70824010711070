import React, { ReactNode } from 'react';
import styled from 'styled-components';

/*---> Component <---*/
const FooterWrapper = ({ children }: PropsType) => {
  return <Wrapper>{children}</Wrapper>;
};

/*---> Styles <---*/
export const Wrapper = styled.footer`
  width: calc(100% - 20px); // Adjust width to account for padding
  margin: 20px auto; // Center the wrapper
  padding: 20px 10px 20px 10px; // Padding on all sides
  display: flex;
  justify-content: center;
  align-items: center;
  border: 9px solid #FFFee3; // Full border with specified color
  border-radius: 10px; // Rounded corners
  background-color: #b71e24; // Dark red background
  box-sizing: border-box;

  ul {
    list-style-type: none; // Remove default list styling
    padding: 0;
    margin: 0;
    text-align: left;

    li {
      margin-bottom: 10px; // More vertical spacing between list items

      &:last-child {
        margin-bottom: 0; // Remove bottom margin for the last item
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column; // Stack content vertically on mobile
    padding: 20px; // Adjust padding for mobile view
  }

  font-family: 'Times New Roman', Times, serif; // Classic university-style font
  color: #FFFee3; // Cream font color
  font-size: 16px; // Increased font size
`;

/*---> Interfaces <---*/
interface PropsType {
  children?: ReactNode;
}

export default FooterWrapper;
