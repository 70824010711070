import React, { ReactNode } from 'react'
import styled from 'styled-components'

/*---> Component <---*/
const Navbar = ({ children, hideNav }: PropsType) => {
  return (
    <NavContainer className={hideNav ? 'hide': ''}>
      <Wrapper>{children}</Wrapper>
    </NavContainer>
  )
}

export const NavContainer = styled.div`
  position: relative;
  background-color: #b71e24;
  padding: 12px 43px;
  width: 100%;
  z-index: 999;
  &.hide {
    background-color: transparent;
  }

  @media (max-width: 768px) {
    padding: 12px 36px;
  }
`;

/*---> Styles <---*/
export const Wrapper = styled.nav`
  max-width: 1850px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
`

/*---> Interfaces <---*/
interface PropsType {
  children?: ReactNode;
  hideNav?: boolean;
}

export default Navbar
