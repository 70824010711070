import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

/*---> Component <---*/
const Logo = () => {
  const history = useHistory()

  function handleClick() {
    history.push('/')
  }

  return (
    <Image
      src='/images/misc/logo2.png'
      alt='OUAlumni.org logo'
      onClick={handleClick}
    />
  )
}

/*---> Styles <---*/
export const Image = styled.img`
  height: 119px;
  cursor: pointer;
  margin-right: 40px;

  @media (max-width: 768px) {
    height: 74px;
  }
`

export default Logo
