import {
  IAthlete,
  ICity,
  ICountry,
  IHobby,
  IIbcCompany,
  IIndustry,
  IName,
  ISchool,
} from '../../lib/entities';
import { IUtilAction } from './util.type';

interface IUtilState {
  degrees: IName[];
  grad_levels: IName[];
  ibc_companies: IIbcCompany[];
  student_orgs: IName[];
  associations: IName[];
  industries: IIndustry[];
  hobbies: IHobby[];
  job_titles: IName[];
  military_branches: IName[];
  schools?: ISchool[];
  cities?: ICity[];
  countries?: ICountry[];
  skills?: IName[];
  org_jobs?: IName[];
  athletes?: IAthlete[];
  romances?: IName[];
  alumni_clubs?: IName[];
  sports_watches?: IName[];
  sec_games: IName[];
}

const initState: IUtilState = {
  degrees: [],
  grad_levels: [],
  ibc_companies: [],
  student_orgs: [],
  associations: [],
  industries: [],
  hobbies: [],
  job_titles: [],
  military_branches: [],
  schools: [],
  cities: [],
  countries: [],
  skills: [],
  org_jobs: [],
  sec_games: [],
};

const utilReducer = (state = initState, action: IUtilAction): IUtilState => {
  switch (action.type) {
    case 'UTIL/SET_DEGREES': {
      return { ...state, degrees: action.payload };
    }

    case 'UTIL/SET_GRAD_LEVELS': {
      return { ...state, grad_levels: action.payload };
    }

    case 'UTIL/SET_IBC_COMPANIES': {
      return { ...state, ibc_companies: action.payload };
    }

    case 'UTIL/SET_STUDENT_ORGS': {
      return { ...state, student_orgs: action.payload };
    }

    case 'UTILS/SET_ASSOCIATIONS': {
      return { ...state, associations: action.payload };
    }

    case 'UTILS/SET_INDUSTRIES': {
      return { ...state, industries: action.payload };
    }

    case 'UTILS/SET_HOBBIES': {
      return { ...state, hobbies: action.payload };
    }

    case 'UTILS/SET_JOB_TITLES': {
      return { ...state, job_titles: action.payload };
    }

    case 'UTILS/SET_MILITARY_BRANCHES': {
      return { ...state, military_branches: action.payload };
    }

    case 'UTILS/SET_SCHOOLS': {
      return { ...state, schools: action.payload };
    }

    case 'UTILS/SET_CITIES': {
      return { ...state, cities: action.payload };
    }

    case 'UTILS/SET_COUNTRIES': {
      return { ...state, countries: action.payload };
    }

    case 'UTILS/SET_SKILLS': {
      return { ...state, skills: action.payload };
    }

    case 'UTILS/SET_ORG_JOBS': {
      return { ...state, org_jobs: action.payload };
    }

    case 'UTILS/SET_ATHLETES': {
      return { ...state, athletes: action.payload };
    }

    case 'UTILS/SET_ROMANCES': {
      return { ...state, romances: action.payload };
    }

    case 'UTILS/SET_ALUMNI_CLUBS': {
      return { ...state, alumni_clubs: action.payload };
    }

    case 'UTILS/SET_SPORTS_WATCHES': {
      return { ...state, sports_watches: action.payload };
    }

    case 'UTILS/SET_SEC_GAMES': {
      return { ...state, sec_games: action.payload };
    }

    default: {
      return state;
    }
  }
};

export default utilReducer;
