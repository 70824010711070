import styled from 'styled-components';

/*---> Styles <---*/
export const MobileNavLink = styled.div`
  display: none;
  height: fit-content;
  color: #ffee3;
  font-size: 22px;
  font-weight: 500;
  padding: 12px 12px;
  margin: 0;
  cursor: pointer;
  border: 0;
  white-space: nowrap;

  @media (max-width: 1160px) {
    display: block;
  }

  &:hover {
    background-color: #b71e24;
  }
`;

export default MobileNavLink;
