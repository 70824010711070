import React from 'react';
import Seperator from '../components/Seperator/Seperator';
import AccordionCompound from '../compounds/AccordionCompound';
import HeaderCompound from '../compounds/HeaderCompound';
import JumboCompound from '../compounds/JumboCompound';
import styled from 'styled-components';

// Component for HomePage
const HomePage = () => {
  return (
    <>
      <HeaderCompound />
      <FeatureWrapper>
        <FeatureImageContainer>
          <FeatureImage src='https://alchetron.com/cdn/michael-f-price-85770a4f-c675-427a-9c1a-9145d374475-resize-750.jpg' alt='Michael F. Price' />
          <ImageOverlay>Michael F. Price</ImageOverlay>
        </FeatureImageContainer>
        <FeatureText>
          <Quote>
            Always keep learning and adapting. The business world is constantly evolving, and successful investors are those who stay curious, open-minded, and committed to continuous improvement.
          </Quote>
          <Citation>
            <p>
              ~ <a href="https://en.wikipedia.org/wiki/Michael_F._Price">Michael F. Price</a> ~
              July 3, 1951 to March 14, 2022
              In Memory of a Great OU Alumni
            </p>
          </Citation>
        </FeatureText>
      </FeatureWrapper>
      <Seperator />
      <VideoSection>
        <ResponsiveIframe
          src="https://www.youtube.com/embed/4f-_SjZiIgM?si=oNNVt7mFoboKpcwk"
          title="YouTube Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <VideoDescription>
          <p>Check out this informative video for more insights and inspiration.</p>
        </VideoDescription>
      </VideoSection>
      <JumboCompound />
      <AccordionCompound />
      <Seperator />
    </>
  );
};

export default HomePage;

// Styled Components
const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FeatureImageContainer = styled.div`
  position: relative;
  margin-right: 3px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const FeatureImage = styled.img`
  filter: grayscale(100%);
  transform: scale(0.89);
  border-radius: 33px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5); // Added shadow

  @media (max-width: 768px) {
    width: 80%; // Adjust for smaller screens
  }
`;

const ImageOverlay = styled.span`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 20px;
`;

const FeatureText = styled.div`
  text-align: center;
`;

const Quote = styled.div`
  font-size: 36px;
  background-color: #fffee3;
  color: #b71e24;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  max-width: 80%;
  margin: 23px auto;
`;

const Citation = styled.div`
  margin: 23px auto;
`;

const VideoSection = styled.div`
  margin: 20px;
  text-align: center;
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 300px;

  @media (max-width: 768px) {
    height: 200px; // Adjust height for smaller screens
  }
`;

const VideoDescription = styled.div`
  margin-top: 10px;
`;
