import { useEffect } from 'react';
import HomePage from './pages/HomePage';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import SigninPage from './pages/auth/SigninPage';
import RegisterPage from './pages/auth/RegisterPage';
import BrowsePage from './pages/BrowsePage';
import NotFoundPage from './pages/NotFoundPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/rootReducer';
import { Dispatch } from 'redux';
import {
  loadAlumniClubs,
  loadAssociations,
  loadAthletes,
  loadCities,
  loadCountries,
  loadDegrees,
  loadGradLevels,
  loadHobbies,
  loadIbcCompanies,
  loadIndustries,
  loadJobTitles,
  loadMilitaryBranches,
  loadOrgJobs,
  loadRomances,
  loadSchools,
  loadSkills,
  loadSportsWatches,
  loadStudentOrgs,
  loadSecGames,
} from './store/util/util.actions';
import ProfilePage from './pages/profile/ProfilePage';
import { authLoadMe } from './store/auth/auth.actions';
import UsersPage from './pages/UsersPage';
import HistoryPage from './pages/HistoryPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';
import VerifyPage from './pages/auth/VerifyPage';
import PrivacyPage from './pages/PrivacyPage';
import SettingsPage from './pages/profile/SettingsPage';
import InvitePage from './pages/profile/InvitePage';
import './app.css';

const appLoadConfigs = async (dispatch: Dispatch<any>) => {
  (async () => {
    await dispatch(loadDegrees());
    await dispatch(loadGradLevels());
    await dispatch(loadIbcCompanies());
  })();
  (async () => {
    await dispatch(loadStudentOrgs());
    await dispatch(loadAssociations());
    await dispatch(loadIndustries());
  })();
  (async () => {
    await dispatch(loadHobbies());
    await dispatch(loadJobTitles());
    await dispatch(loadMilitaryBranches());
  })();
  (async () => {
    await dispatch(loadSchools());
    await dispatch(loadCities());
    await dispatch(loadCountries());
  })();
  (async () => {
    await dispatch(loadSkills());
    await dispatch(loadOrgJobs());
    await dispatch(loadAthletes());
  })();
  (async () => {
    await dispatch(loadRomances());
    await dispatch(loadAlumniClubs());
    await dispatch(loadSportsWatches());
    await dispatch(loadSecGames());
  })();
};

const App = () => {
  const dispatch = useDispatch<any>();

  const { token, profile } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    appLoadConfigs(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(authLoadMe());
    }
  }, [dispatch, token]);

  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <HomePage />
        </Route>
        <Route path='/privacy'>
          <PrivacyPage />
        </Route>
        <Route path='/reset-password/:token'>
          <ResetPasswordPage />
        </Route>
        {!token ? (
          <>
            <Route path='/signin'>
              <SigninPage />
            </Route>
            <Route path='/signup'>
              <RegisterPage />
            </Route>
            <Route path='/forgot-password'>
              <ForgotPasswordPage />
            </Route>
            <Route path='/verify'>
              <VerifyPage />
            </Route>
          </>
        ) : !profile?.is_verified ? (
          <Route path='/verify'>
            <VerifyPage />
          </Route>
        ) : (
          <>
            <Route path='/profile'>
              <ProfilePage />
            </Route>
            <Route path='/settings'>
              <SettingsPage />
            </Route>
            <Route path='/invite'>
              <InvitePage />
            </Route>
            <Route path='/users'>
              <UsersPage />
            </Route>
            <Route path='/history'>
              <HistoryPage />
            </Route>
            <Route path='/browse'>
              <BrowsePage />
            </Route>
          </>
        )}
        <Route path='*'>
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
};
export default App;
