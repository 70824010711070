import styled from 'styled-components';
import banner from '../../assets/images/final.png';
/*---> Component <---*/
const Banner = () => {
  return (
    <BannerWrapper>
      <Image src={banner} alt='OUAlumni.org banner' />
    </BannerWrapper>
  );
};

/*---> Styles <---*/
export const BannerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute; // Add this line
    
`;
export const Image = styled.img`
  width: 100%;
  min-height: 260px;

  @media (max-width: 768px) {
    // margin-top: 80px;
  }

  @media (max-width: 550px) {
    // margin-top: 140px;
  }

  @media (max-width: 370px) {
    // margin-top: 180px;
  }
`;

export default Banner;
