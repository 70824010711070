import { IUserProfile } from './entities';

export const compareTraits = (
  u1: IUserProfile,
  u2: IUserProfile,
  unit: 'PERCENT' | 'FIXED' = 'FIXED',
) => {
  let res =
      u1.degrees?.filter(c => u2.degrees?.some(x => x.id === c.id)).length ?? 0,
    tot = (u1.degrees?.length ?? 0) + (u2.degrees?.length ?? 0);

  res +=
    u1.grad_levels?.filter(c => u2.grad_levels?.some(x => x.id === c.id))
      .length ?? 0;
  tot += (u1.grad_levels?.length ?? 0) + (u2.grad_levels?.length ?? 0);

  res += u1.ibc_company_id === u2.ibc_company_id ? 1 : 0;
  tot += u1.ibc_company_id || u2.ibc_company_id ? 2 : 0;

  res +=
    u1.associations?.filter(c => u2.associations?.some(x => x.id === c.id))
      .length ?? 0;
  tot += (u1.associations?.length ?? 0) + (u2.associations?.length ?? 0);

  res +=
    u1.schools?.filter(c => u2.schools?.some(x => x.id === c.id)).length ?? 0;
  tot += (u1.schools?.length ?? 0) + (u2.schools?.length ?? 0);

  res +=
    u1.hobbies?.filter(c => u2.hobbies?.some(x => x.id === c.id)).length ?? 0;
  tot += (u1.hobbies?.length ?? 0) + (u2.hobbies?.length ?? 0);

  res +=
    u1.industries?.filter(c => u2.industries?.some(x => x.id === c.id))
      .length ?? 0;
  tot += (u1.industries?.length ?? 0) + (u2.industries?.length ?? 0);

  res +=
    u1.job_titles?.filter(c => u2.job_titles?.some(x => x.id === c.id))
      .length ?? 0;
  tot += (u1.job_titles?.length ?? 0) + (u2.job_titles?.length ?? 0);

  res +=
    u1.military_branch && u1.military_branch?.id === u2.military_branch?.id
      ? 1
      : 0;
  tot += u1.military_branch || u2.military_branch ? 2 : 0;

  res +=
    u1.cities?.filter(c => u2.cities?.some(x => x.id === c.id)).length ?? 0;
  tot += (u1.cities?.length ?? 0) + (u2.cities?.length ?? 0);

  res +=
    u1.countries?.filter(c => u2.countries?.some(x => x.id === c.id)).length ??
    0;
  tot += (u1.countries?.length ?? 0) + (u2.countries?.length ?? 0);

  res += u1.grad_year && u1.grad_year === u2.grad_year ? 1 : 0;
  tot += u1.grad_year || u2.grad_year ? 2 : 0;

  res +=
    u1.athletes && u2.athletes && u1.athletes.name === u2.athletes.name ? 1 : 0;

  res += u1.romance && u2.romance && u1.romance === u2.romance ? 1 : 0;

  if (u1.alumni_clubs && u2.alumni_clubs) {
    res += u1.alumni_clubs.filter(x => u2.alumni_clubs?.includes(x)).length;
  }

  if (u1.sports_watches && u2.sports_watches) {
    res += u1.sports_watches.filter(x => u2.sports_watches?.includes(x)).length;
  }

  tot -= res;

  return unit === 'FIXED' ? res : (res / tot) * 100;
};
