import React, { ReactNode } from 'react';
import styled from 'styled-components'

const MenuWrapper = styled.div`
  position: absolute;
  top: 124px;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  background-color: #b71e24;
  box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 30%);

  display: none;
  border: 0;
  height: 0;
  overflow: hidden;

  padding: 0px 50px;
  transition: all 0.3s ease-out;
  
  &.open {
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: all 0.3s ease-out;
  }

  @media (max-width: 1160px) {
    display: block;
  }

  @media (max-width: 768px) {
    top: 98px;
    padding-left: 36px;
    padding-right: 36px;
  }
`;

const MobileMenu = ({ open, children }: PropsType) => (
    <MenuWrapper className={open ? 'open' : ''}>
      {children}
    </MenuWrapper>
);

/*---> Interfaces <---*/
interface PropsType {
  open?: boolean;
  children?: ReactNode;
}

export default MobileMenu;
