import React, { ReactNode } from 'react';
import styled from 'styled-components';

/*---> Component <---*/
const SignFormWInput = ({ children, ...restProps }: PropsType) => {
  return <FormInput {...restProps}>{children}</FormInput>;
};

/*---> Styles <---*/
export const FormInput = styled.input`
  background: #b71e24;
  border-radius: 4px;
  border: 0;
  color: #fffee3;
  height: 50px;
  line-height: 50px;
  padding: 5px 20px;
  margin-bottom: 20px;

  ::placeholder,
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #FFFee3;
    opacity: 1;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #FFFee3;
    opacity: 1;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #FFFee3;
    opacity: 1;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #FFFee3;
    opacity: 1;
`;

/*---> Interfaces <---*/
interface PropsType {
  children?: ReactNode;
  type?: string;
  placeholder?: string;
  value?: string;
  autoComplete?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: ({ target }: { target: any }) => void;
}

export default SignFormWInput;
