import { useCallback, useEffect, useMemo, useState } from 'react';
import FooterCompound from '../compounds/FooterCompound';
import HeaderCompound from '../compounds/HeaderCompound';
import { IUserProfile } from '../lib/entities';
import { sendRequest } from '../lib/api';
import { Input, Select, Table, Tag } from 'antd';
import UserAvatar from '../components/Card/UserAvatar';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import ReactPlayer from 'react-player';
import UserModal from '../compounds/UserModal';

interface IUserRow {
  id: number;
  email: string | null;
  name: string;
  avatar?: string;
  headline: string | null;
  website_link: string | null;
  youtube_video_url: string | null;
  linkedin_url: string | null;
  traits: Array<{ name: string; is_match: boolean }>;
  match_count: number;
}

const toUserRow = (user: IUserProfile, me: IUserProfile) => {
  let result: IUserRow = {
    id: user.id,
    email: user.email,
    name: `${user.first_name} ${user.last_name} ${
      user.nickname ? `(${user.nickname})` : ''
    }`,
    avatar: user.profile_pic ?? undefined,
    headline: user.headline,
    website_link: user.website_link,
    youtube_video_url: user.youtube_video_url,
    traits: [],
    match_count: 0,
    linkedin_url: user.linkedin_url,
  };
  if (user.countries) {
    const tmp = user.countries.map(t => ({
      name: t.name,
      is_match: me.countries?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.associations) {
    const tmp = user.associations.map(t => ({
      name: t.name,
      is_match: me.associations?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.schools) {
    const tmp = user.schools.map(t => ({
      name: t.name,
      is_match: me.schools?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.hobbies) {
    const tmp = user.hobbies.map(t => ({
      name: t.name,
      is_match: me.hobbies?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.industries) {
    const tmp = user.industries.map(t => ({
      name: t.name,
      is_match: me.industries?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.job_titles) {
    const tmp = user.job_titles.map(t => ({
      name: t.name,
      is_match: me.job_titles?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.cities) {
    const tmp = user.cities.map(t => ({
      name: t.name,
      is_match: me.cities?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.degrees) {
    const tmp = user.degrees.map(t => ({
      name: t.name,
      is_match: me.degrees?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.grad_levels) {
    const tmp = user.grad_levels.map(t => ({
      name: t.name,
      is_match: me.grad_levels?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.ibc_company) {
    result.traits.push({
      name: user.ibc_company.name,
      is_match: me.ibc_company?.id === user.ibc_company.id,
    });
  }
  if (user.military_branch) {
    result.traits.push({
      name: user.military_branch.name,
      is_match: me.military_branch?.id === user.military_branch.id,
    });
  }
  if (user.grad_year) {
    result.traits.push({
      name: user.grad_year.toString(),
      is_match: user.grad_year === me.grad_year,
    });
  }
  if (user.skills) {
    const tmp = user.skills.map(t => ({
      name: t.name,
      is_match: me.skills?.some(y => y.id === t.id) ?? false,
    }));
    result.traits.push(...tmp);
  }
  if (user.org_job_groups) {
    const tmp = user.org_job_groups.map(x => ({
      name: `${x.org.name} - ${x.jobs.map(y => y.name).join(',')}`,
      is_match: me.org_job_groups.some(y => x.org.id === y.org.id),
    }));
    result.traits.push(...tmp);
  }
  if (user.athletes) {
    result.traits.push({
      name: `${user.athletes.name} - ${user.athletes.positions?.join(',')}`,
      is_match: user.athletes.name === me.athletes?.name,
    });
  }
  if (user.sports_watches) {
    result.traits.push(
      ...user.sports_watches.map(x => ({
        name: x,
        is_match: me.sports_watches?.includes(x) ?? false,
      })),
    );
  }
  if (user.alumni_clubs) {
    result.traits.push(
      ...user.alumni_clubs.map(x => ({
        name: x,
        is_match: me.alumni_clubs?.includes(x) ?? false,
      })),
    );
  }
  if (user.romance) {
    result.traits.push({
      name: user.romance,
      is_match: user.romance === me.romance,
    });
  }
  result.match_count = result.traits.filter(x => x.is_match).length;

  return result;
};

const columns = (openUser: (id: number) => void) => [
  {
    key: 'avatar',
    title: 'Photo, Name, Email:',
    width: 140,
    render: (x: IUserRow) =>
      x.avatar && (
        <div className='avatar-wrap' onClick={() => openUser(x.id)}>
          <div className='text-center'>
            <UserAvatar src={x.avatar} size={140} onClick={() => {}} />
          </div>
          <div className='text-center'>
            {x.email && <a href={`mailto:${x.email}`}>{x.email}</a>}
            <div>{x.name}</div>
          </div>
        </div>
      ),
  },
  {
    key: 'youtube',
    title: 'Youtube:',
    render: (x: IUserRow) =>
      x.youtube_video_url && (
        <div className='video-wrap'>
          <ReactPlayer width='auto' height={280} url={x.youtube_video_url} />
        </div>
      ),
    width: 430,
  },
  {
    key: 'headline',
    title: 'About:',
    render: (x: IUserRow) => <span>{x.headline}</span>,
  },
  {
    key: 'website',
    title: 'LinkedIn:',
    render: (x: IUserRow) => (
      <div>
        {!!x.linkedin_url && (
          <a href={x.linkedin_url} target='_blank' rel='noreferrer'>
            {x.linkedin_url}
          </a>
        )}
        {!!x.website_link && (
          <a href={x.website_link} target='_blank' rel='noreferrer'>
            {x.website_link}
          </a>
        )}
      </div>
    ),
  },
  {
    key: 'traits',
    title: 'Matching Traits:',
    render: (user: IUserRow) => (
      <div>
        <div style={{ fontWeight: 'bold' }}>
          {`${user.match_count} matching traints!`}
        </div>
        {user.traits.map((x, i) => (
          <Tag key={i} color={x.is_match ? '#8d1e20' : 'gold'}>
            {x.name}
          </Tag>
        ))}
      </div>
    ),
    width: '27%',
  },
];

const BrowsePage = () => {
  const profile = useSelector((state: RootState) => state.auth.profile);

  const [users, setUsers] = useState<IUserProfile[]>();
  const [rows, setRows] = useState<IUserRow[]>();
  const [keyword, setKeyword] = useState('');
  const [openUser, setOpenUser] = useState<IUserProfile>();
  const [keyTraits, setKeyTraits] = useState<string[]>([]);
  const [condition, setCondition] = useState<'all' | 'one'>('all');

  const loadData = useCallback(async () => {
    if (profile) {
      const res = await sendRequest({}, 'GET', 'api/users');
      setUsers(res.data);
      const tmp: IUserRow[] = res.data.map((x: any) => toUserRow(x, profile));
      tmp.sort((a, b) => b.match_count - a.match_count);
      setRows(tmp);
    }
  }, [profile]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const traitOptions = useMemo(() => {
    let result = ['JC Penny'];
    if (rows) {
      for (let x of rows) {
        for (let y of x.traits) {
          if (!result.includes(y.name)) result.push(y.name);
        }
      }
    }
    return result.map(x => ({ key: x, value: x, label: x }));
  }, [rows]);

  const filtered = useMemo(() => {
    let result = rows ? [...rows] : [];
    if (keyword) {
      const k = keyword.trim().toLowerCase();
      result = result?.filter(
        x =>
          x.email?.toLowerCase().includes(k) ||
          x.name.toLowerCase().includes(k) ||
          x.headline?.toLowerCase().includes(k),
      );
    }
    if (keyTraits && keyTraits.length > 0) {
      if (condition === 'all') {
        result = result?.filter(x =>
          keyTraits.every(y => x.traits.some(k => k.name === y)),
        );
      } else {
        result = result.filter(x =>
          x.traits.some(y => keyTraits.includes(y.name)),
        );
      }
    }
    return result;
  }, [rows, keyword, condition, keyTraits]);

  const onClickUser = (id: number) =>
    setOpenUser(users?.find(x => x.id === id));

  return (
    <div>
      <HeaderCompound hideFeature={true} size='md' />
      <div className='browse-body'>
        <div className='search-bar'>
          <div
            className='search-box'
            style={{ flexBasis: '50%', minWidth: '300px', padding: '10px' }}
          >
            <label>Search by name</label>
            <Input
              placeholder='full name, email, headline'
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
            />
          </div>
          <div
            className='search-box'
            style={{ flexBasis: '50%', minWidth: '300px', padding: '10px' }}
          >
            <Select
              style={{ width: '100%' }}
              options={[
                { value: 'all', label: 'Search by all traits' },
                { value: 'one', label: 'Search by at least one' },
              ]}
              value={condition}
              onChange={v => setCondition(v)}
            />
            <Select
              mode='multiple'
              style={{ width: '100%' }}
              allowClear
              value={keyTraits}
              options={traitOptions}
              onChange={v => setKeyTraits(v)}
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>
        </div>
        <Table
          rowKey={'id'}
          columns={columns(onClickUser)}
          dataSource={filtered}
          pagination={{ pageSize: 5 }}
        />
      </div>
      {openUser && (
        <UserModal
          visible={true}
          user={openUser}
          onClose={() => setOpenUser(undefined)}
          onLoad={loadData}
        />
      )}
      <FooterCompound />
    </div>
  );
};

export default BrowsePage;
