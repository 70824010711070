import { Avatar, Button, Table, Tag } from 'antd';
import HeaderCompound from '../../compounds/HeaderCompound';
import { useCallback, useEffect, useState } from 'react';
import InviteUserModal from '../../components/alumni/InviteUserModal';
import { sendRequest } from '../../lib/api';
import { IUserProfile } from '../../lib/entities';
import UserModal from '../../compounds/UserModal';
import { UserOutlined } from '@ant-design/icons';
import './styles.css';

interface IInvite {
  id: number;
  email: string;
  is_accepted: boolean;
  created_at: string;
  updated_at: string;
  accepted_user?: IUserProfile;
}

const columns = (onClick: (d: any) => void) => [
  {
    key: 'email',
    title: 'User info',
    render: (x: IInvite) =>
      x.accepted_user ? (
        <div
          style={{ cursor: 'pointer', width: '100px' }}
          onClick={() => onClick(x.accepted_user)}
        >
          <div className='text-center'>
            <Avatar
              shape='circle'
              size={100}
              src={x.accepted_user.profile_pic}
              icon={<UserOutlined />}
            />
          </div>
          <div className='text-center'>
            <Tag color={'#87d068'}>
              {`${x.accepted_user.first_name} ${x.accepted_user.last_name}`}
            </Tag>
          </div>
        </div>
      ) : (
        <a href={`mailto:${x.email}`}>{x.email}</a>
      ),
  },
  {
    key: 'is_accepted',
    title: 'Status',
    render: (x: IInvite) =>
      x.is_accepted ? (
        <>
          <Tag color={'#87d068'}>
            {`Accepted from ${x.accepted_user?.first_name} ${x.accepted_user?.last_name}`}
          </Tag>
          <a href={`mailto:${x.email}`}>{x.email}</a>
        </>
      ) : (
        <Tag color={'gold'}>{'Pending'}</Tag>
      ),
  },
  {
    key: 'created_at',
    title: 'Invited at',
    render: (x: IInvite) => <span>{x.created_at}</span>,
  },
  {
    key: 'updated_at',
    title: 'Accepted at',
    render: (x: IInvite) => <span>{x.is_accepted ? x.updated_at : '-'}</span>,
  },
];

const InvitePage = () => {
  const [invites, setInvites] = useState<IInvite[]>([]);
  const [showInvite, setShowInvite] = useState(false);
  const [showUser, setShowUser] = useState<IUserProfile>();

  const loadData = useCallback(async () => {
    const res = await sendRequest({}, 'GET', 'api/invites');
    if (res.status) {
      setInvites(res.data);
    } else {
      window.alert(res.data?.message ?? 'Error');
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <HeaderCompound hideFeature={true} />
      <div className='invite-page'>
        <div className='mb-20'>
          <div className='d-flex justify-end mb-20'>
            <div>
              <Button type='primary' onClick={() => setShowInvite(true)}>
                Invite User
              </Button>
            </div>
          </div>
          <Table
            rowKey={'id'}
            dataSource={invites}
            columns={columns(u => setShowUser(u))}
            pagination={false}
          />
        </div>
      </div>
      {showInvite && (
        <InviteUserModal
          visible={true}
          onClose={() => {
            loadData();
            setShowInvite(false);
          }}
        />
      )}
      {!!showUser && (
        <UserModal
          visible={true}
          user={showUser}
          onClose={() => setShowUser(undefined)}
          onLoad={loadData}
        />
      )}
    </div>
  );
};

export default InvitePage;
