import { Button, Modal, Select, Tag } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { IOrgJobGroup } from '../../lib/entities';

interface ModalProp {
  data?: IOrgJobGroup;
  onConfirm: (d: IOrgJobGroup) => void;
  onClose: () => void;
}

const UpdateModal = ({ data, onConfirm, onClose }: ModalProp) => {
  const { orgItems, jobItems } = useSelector((state: RootState) => ({
    orgItems: state.utils.student_orgs,
    jobItems: state.utils.org_jobs ?? [],
  }));

  const [studentOrg, setStudentOrg] = useState(data?.org);
  const [orgJobs, setOrgJobs] = useState(data?.jobs);

  const selectOrgItem = (v: string) => {
    const org = orgItems.find(x => x.id === Number(v));
    setStudentOrg(org);
  };
  const selectJobItems = (vs: string[]) => {
    const jobs = vs
      .map(v => jobItems.find(x => x.id === Number(v)))
      .filter(x => !!x);
    setOrgJobs(jobs as any);
  };
  const confirmClick = () => {
    if (!studentOrg) return;
    onConfirm({ org: studentOrg, jobs: orgJobs ?? [] });
    onClose();
  };

  return (
    <Modal
      title='Add student orgs and your positions.'
      onCancel={onClose}
      onOk={confirmClick}
      open={true}
    >
      <div className='wrap'>
        <div style={{ marginBottom: '20px', marginTop: '30px' }}>
          <label>Select an organization:</label>
          <Select
            style={{ width: '100%' }}
            options={orgItems.map(x => ({
              id: x.id.toString(),
              label: x.name,
              value: x.id.toString(),
            }))}
            value={studentOrg?.id.toString()}
            onChange={v => selectOrgItem(v)}
            showSearch={true}
            filterOption={(inputValue, option) =>
              (option?.label ?? '')
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          />
        </div>
        <div style={{ marginBottom: '30px' }}>
          <label>What was your position?</label>
          <Select
            style={{ width: '100%' }}
            mode='multiple'
            options={jobItems.map(x => ({
              id: x.id.toString(),
              label: x.name,
              value: x.id.toString(),
            }))}
            value={orgJobs?.map(x => x.id.toString())}
            onChange={v => selectJobItems(v)}
            showSearch={true}
            filterOption={(inputValue, option) =>
              (option?.label ?? '')
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            }
          />
        </div>
      </div>
    </Modal>
  );
};

interface Props {
  data?: IOrgJobGroup[];
  onChange: (d: IOrgJobGroup[]) => void;
}

const OrgJobGroups = ({ data, onChange }: Props) => {
  const [editGroup, setEditGroup] = useState<IOrgJobGroup>();
  const [showAdd, setShowAdd] = useState(false);

  const removeOrgClick = (orgID: number) => {
    const tmp = data?.filter(x => x.org.id !== orgID);
    onChange(tmp ?? []);
  };
  const removeTagClick = (orgID: number, tagID: number) => {
    let tmp = data ? [...data] : [];
    const i = tmp.findIndex(x => x.org.id === orgID);
    tmp[i].jobs = tmp[i].jobs.filter(x => x.id !== tagID);
    onChange(tmp);
  };
  const addConfirm = (d: IOrgJobGroup) => {
    const tmp = data ? [...data, d] : [d];
    onChange(tmp);
  };
  const editConfirm = (d: IOrgJobGroup) => {
    let tmp = data ? [...data] : [];
    const i = tmp.findIndex(x => x.org.id === d.org.id);
    tmp[i] = d;
    onChange(tmp);
  };

  return (
    <div style={{ padding: '10px' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='primary' onClick={() => setShowAdd(true)} size='small'>
          Add organization
        </Button>
      </div>
      {data?.map((t, i) => (
        <div key={i} style={{ marginBottom: '10px', marginTop: '10px' }}>
          <Tag
            closeIcon
            onClose={e => {
              e.preventDefault();
              removeOrgClick(t.org.id);
            }}
            onClick={() => setEditGroup(t)}
          >
            {t.org.name}
          </Tag>
          <div
            style={{ paddingLeft: '20px', display: 'flex', flexWrap: 'wrap' }}
          >
            {t.jobs.map(x => (
              <div key={x.id} style={{ marginTop: '5px' }}>
                <Tag
                  closeIcon
                  onClose={e => {
                    e.preventDefault();
                    removeTagClick(t.org.id, x.id);
                  }}
                >
                  {x.name}
                </Tag>
              </div>
            ))}
          </div>
        </div>
      ))}
      {showAdd && (
        <UpdateModal
          onClose={() => setShowAdd(false)}
          onConfirm={d => addConfirm(d)}
        />
      )}
      {editGroup && (
        <UpdateModal
          data={editGroup}
          onClose={() => setEditGroup(undefined)}
          onConfirm={d => editConfirm(d)}
        />
      )}
    </div>
  );
};

export default OrgJobGroups;
