import { useState, useEffect } from 'react';
import HeaderCompound from '../../compounds/HeaderCompound';
import FooterCompound from '../../compounds/FooterCompound';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import SignFormWrapper from '../../components/SignForm/SignFormWrapper';
import SignFormBase from '../../components/SignForm/SignFormBase';
import SignFormTitle from '../../components/SignForm/SignFormTitle';
import SignFormInput from '../../components/SignForm/SignFormInput';
import ImgCrop from 'antd-img-crop';
import { Button, Checkbox, Collapse, Input, Select, Upload } from 'antd';
import CameraIcon from '../../components/icons/CameraIcon';
import { Avatar } from 'antd';
import { UserOutlined, AppstoreOutlined } from '@ant-design/icons';
import SignFormButton from '../../components/SignForm/SignFormButton';
import { updateProfile } from '../../store/auth/auth.actions';
import TextArea from 'antd/es/input/TextArea';
import Loading from '../../assets/images/loading.gif';
import OrgJobGroups from '../../components/alumni/OrgJobGroups';
import { IMilitaryCode, IOrgJobGroup } from '../../lib/entities';
import Seperator from '../../components/Seperator/Seperator';
import { sendMultiPart, sendRequest } from '../../lib/api';

interface IFormData {
  first_name: string;
  last_name: string;
  nickname: string | null;
  linkedin_url: string | null;
  grad_level_id: number[] | null;
  degree_id: number[] | null;
  ibc_company_id: number | null;
  association_id: number[] | null;
  industry_id: number[] | null;
  hobby_id: number[] | null;
  city_id: number[] | null;
  country_id: number[] | null;
  job_title_id: number[] | null;
  military_branch_id: number | null;
  military_code_id: number | null;
  profile_pic?: string | null;
  profile_url?: string | null;
  headline: string | null;
  youtube_video_url: string | null;
  video_url: string | null;
  grad_year: string | null;
  type: 'OWN' | 'JOIN' | null;
  skills_id: number[] | null;
  org_job_groups?: IOrgJobGroup[];
  athletes?: { name: string; positions: string[] | undefined } | null;
  romance?: string | null;
  sports_watches?: string[] | null;
  alumni_clubs?: string[] | null;
  sec_games: string | null;
}

const ProfilePage = () => {
  const dispatch = useDispatch<any>();

  const profile = useSelector((state: RootState) => state.auth.profile);
  const utils = useSelector((state: RootState) => state.utils);

  const [formData, setFormData] = useState<IFormData>();
  const [loading, setLoading] = useState(false);
  const [schoolIDs, setSchoolIDs] = useState(
    profile?.schools?.filter(x => x.type === 'high_school').map(x => x.id) ??
      [],
  );
  const [collegeIDs, setCollegeIDs] = useState(
    profile?.schools?.filter(x => x.type === 'college').map(x => x.id) ?? [],
  );
  const [mosCodes, setMosCodes] = useState<IMilitaryCode[]>([]);
  const [videoFile, setVideoFile] = useState<any>();
  const [percent, setPercent] = useState<number>();

  const IsInvalid =
    !formData ||
    formData.first_name.length <= 0 ||
    formData.last_name.length <= 0;

  const loadMosData = async (military_branch_id: number) => {
    const res = await sendRequest(
      { military_branch_id },
      'GET',
      'api/military_codes',
    );
    if (res.status) {
      setMosCodes(res.data);
    }
  };
  const updateForm = (key: keyof IFormData, value: any) => {
    if (key === 'military_branch_id') {
      loadMosData(value);
    }
    setFormData(prv => (prv ? { ...prv, [key]: value } : undefined));
  };
  const handleFileChange = (type: 'profile_pic' | 'profile_url', file: any) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      updateForm(type, reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    let form = { ...formData, school_id: [...schoolIDs, ...collegeIDs] };
    if (form.profile_pic && !form.profile_pic.startsWith('data:image')) {
      delete form.profile_pic;
    }
    if (form.profile_url && !form.profile_url.startsWith('data:image')) {
      delete form.profile_url;
    }
    if (videoFile) {
      const data = new FormData();
      data.append('video', videoFile);
      await sendMultiPart(data, 'POST', 'api/update-files', event => {
        const pc = Math.round((100 * event.loaded) / (event.total ?? 1));
        setPercent(pc);
      });
    }
    const res = await dispatch(updateProfile(form));
    setPercent(undefined);

    setLoading(false);
    if (res !== true) {
      window.alert(res ?? 'Failed to update profile');
    } else {
      window.alert('Profile updated successfully!');
    }
  };

  const accordionItems = [
    {
      key: 'ibc',
      label: 'Were yOU in the IBC program?:',
      children: (
        <div style={{ marginBottom: '30px' }}>
          <label>Which IBC company were you in?</label>
          <Select
            style={{ width: '100%' }}
            allowClear
            showSearch={true}
            value={formData?.ibc_company_id?.toString()}
            options={utils.ibc_companies.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.name,
            }))}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={v => updateForm('ibc_company_id', Number(v))}
          />
        </div>
      ),
    },
    {
      key: 'military',
      label: 'Affiliated with the USA Military?:',
      children: (
        <>
          <div style={{ marginBottom: '30px' }}>
            <label>Which branch do you most associate with?</label>
            <Select
              style={{ width: '100%' }}
              allowClear
              value={formData?.military_branch_id?.toString()}
              options={utils.military_branches.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: x.name,
              }))}
              onChange={v => updateForm('military_branch_id', Number(v))}
              showSearch={true}
              filterOption={(inputValue, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            />
          </div>
          {mosCodes.length > 0 && (
            <div style={{ marginBottom: '30px' }}>
              <label>Which MOS code do you most associate with?</label>
              <Select
                style={{ width: '100%' }}
                allowClear
                value={formData?.military_code_id?.toString()}
                options={mosCodes.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.name} - ${x.rank}`,
                }))}
                onChange={v => updateForm('military_code_id', Number(v))}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'athletes',
      label: 'Were you a student athlete?:',
      children: (
        <div style={{ marginBottom: '30px' }}>
          <label>Please select your sport and position:</label>
          <div style={{ margin: '10px 0' }}>
            <Select
              placeholder='Select your sport:'
              style={{ width: '100%' }}
              value={formData?.athletes?.name}
              options={utils.athletes?.map(x => ({
                id: x.id,
                value: x.name,
                label: x.name,
              }))}
              onChange={t => updateForm('athletes', { name: t })}
            />
          </div>
          <Select
            placeholder='Select your position:'
            mode='multiple'
            style={{ width: '100%' }}
            options={utils.athletes
              ?.find(t => t.name === formData?.athletes?.name)
              ?.positions?.map(x => ({ id: x, label: x, value: x }))}
            value={formData?.athletes?.positions}
            onChange={vs =>
              updateForm('athletes', {
                name: formData?.athletes?.name,
                positions: vs,
              })
            }
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (profile) {
      setFormData(prv => ({
        ...prv,
        ...(profile as any),
        grad_level_id: profile.grad_levels?.map(x => x.id) ?? null,
        degree_id: profile.degrees?.map(x => x.id) ?? null,
        association_id: profile.associations?.map(x => x.id) ?? null,
        industry_id: profile.industries?.map(x => x.id) ?? null,
        hobby_id: profile.hobbies?.map(x => x.id) ?? null,
        city_id: profile.cities?.map(x => x.id) ?? null,
        country_id: profile.countries?.map(x => x.id) ?? null,
        job_title_id: profile.job_titles?.map(x => x.id) ?? null,
        military_branch_id: profile.military_branch?.id ?? null,
        military_code_id: profile.military_code?.id ?? null,
        school_id: profile.schools?.map(x => x.id) ?? null,
        skills_id: profile.skills?.map(x => x.id) ?? null,
        org_job_groups: profile.org_job_groups,
      }));
      if (profile.military_branch) {
        loadMosData(profile.military_branch.id);
      }
    }
  }, [profile]);

  return (
    <>
      <HeaderCompound hideFeature={true} />
      <SignFormWrapper>
        {formData && (
          <SignFormBase onSubmit={handleSubmit} method='POST'>
            <SignFormTitle>Your Profile:</SignFormTitle>
            <SignFormInput
              type='text'
              placeholder='First Name'
              value={formData.first_name}
              onChange={({ target }) => updateForm('first_name', target.value)}
            />
            <SignFormInput
              type='text'
              placeholder='Last Name'
              value={formData.last_name}
              onChange={({ target }) => updateForm('last_name', target.value)}
            />
            <SignFormInput
              type='text'
              placeholder='Nickname'
              value={formData.nickname ?? ''}
              onChange={({ target }) => updateForm('nickname', target.value)}
            />
            <SignFormInput
              type='text'
              placeholder='Email Address'
              disabled={true}
              readOnly={true}
              value={profile?.email ?? ''}
            />
            <Seperator></Seperator>

            <div style={{ marginBottom: '30px' }}>
              <label>Grad Year</label>
              <Select
                placeholder='Select grad year'
                style={{ width: '100%' }}
                allowClear
                value={formData.grad_year}
                options={Array(54)
                  .fill(null)
                  .map((_, i) => (2023 - i).toString())
                  .map(x => ({ id: x, label: x, value: x }))}
                onChange={v => updateForm('grad_year', v)}
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What degree did you major in:</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.degree_id?.map(x => x.toString())}
                options={utils.degrees.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'degree_id',
                    v.map(x => Number(x)),
                  )
                }
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What graduation level did you achieve:</label>
              <Select
                style={{ width: '100%' }}
                mode='multiple'
                allowClear
                value={formData.grad_level_id?.map(x => x.toString())}
                options={utils.grad_levels.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'grad_level_id',
                    v.map(x => Number(x)),
                  )
                }
              />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Seperator></Seperator>
            </div>
            <div className='white-color-panel'>
              <Collapse items={accordionItems} />
            </div>
            <div style={{ marginBottom: '20px' }}>
              <Seperator></Seperator>
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What student organizations were you a part of?</label>
              <div style={{ backgroundColor: 'white', borderRadius: '5px' }}>
                <OrgJobGroups
                  data={formData.org_job_groups}
                  onChange={d => updateForm('org_job_groups', d)}
                />
              </div>
            </div>
            <Seperator></Seperator>
            <div style={{ marginBottom: '30px' }}>
              <label>Single, engaged, or married?:</label>
              <Select
                style={{ width: '100%' }}
                options={utils.romances?.map(x => ({
                  id: x.id,
                  value: x.name,
                  label: x.name,
                }))}
                value={formData.romance}
                onChange={t => updateForm('romance', t)}
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>Select which alumni clubs you identify with most:</label>
              <Select
                style={{ width: '100%' }}
                mode='multiple'
                options={utils.alumni_clubs?.map(x => ({
                  id: x.id,
                  value: x.name,
                  label: x.name,
                }))}
                value={formData.alumni_clubs ?? undefined}
                onChange={vs => updateForm('alumni_clubs', vs)}
                allowClear={true}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>
                Select which sports you watch and identify with most:
              </label>
              <Select
                style={{ width: '100%' }}
                mode='multiple'
                options={utils.sports_watches?.map(x => ({
                  id: x.id,
                  value: x.name,
                  label: x.name,
                }))}
                value={formData.sports_watches ?? undefined}
                onChange={vs => updateForm('sports_watches', vs)}
                allowClear={true}
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What professional associations do you belong to?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.association_id?.map(x => x.toString())}
                options={utils.associations.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'association_id',
                    v.map(x => Number(x)),
                  )
                }
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What industries do you consider yourself a part of?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.industry_id?.map(x => x.toString())}
                options={utils.industries.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'industry_id',
                    v.map(x => Number(x)),
                  )
                }
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>Select your hobbies & interests please:</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.hobby_id?.map(x => x.toString())}
                options={utils.hobbies.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'hobby_id',
                    v.map(x => Number(x)),
                  )
                }
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What cities do you travel to most?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.city_id?.map(x => x.toString())}
                options={utils.cities?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'city_id',
                    v.map(x => Number(x)),
                  )
                }
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What countries do you most associate with?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.country_id?.map(x => x.toString())}
                options={utils.countries?.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'country_id',
                    v.map(x => Number(x)),
                  )
                }
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>Which job titles do yOU associate yourself with?</label>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.job_title_id?.map(x => x.toString())}
                options={utils.job_titles.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: x.name,
                }))}
                onChange={v =>
                  updateForm(
                    'job_title_id',
                    v.map(x => Number(x)),
                  )
                }
                showSearch={true}
                filterOption={(inputValue, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              />
            </div>

            <div style={{ marginBottom: '30px' }}>
              <label>Which high school do you associate yourself with?</label>
              <Select
                mode='multiple'
                showSearch={true}
                style={{ width: '100%' }}
                allowClear
                value={schoolIDs.map(x => x.toString())}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={utils.schools
                  ?.filter(x => x.type === 'high_school')
                  .map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                onChange={v => setSchoolIDs(v.map(x => Number(x)))}
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>Which colleges do you associate yourself with?</label>
              <Select
                mode='multiple'
                showSearch={true}
                style={{ width: '100%' }}
                allowClear
                value={collegeIDs.map(x => x.toString())}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={utils.schools
                  ?.filter(x => x.type === 'college')
                  .map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                onChange={v => setCollegeIDs(v.map(x => Number(x)))}
              />
            </div>
            <div style={{ marginBottom: '30px' }}>
              <label>What SEC Game did you most played?</label>
              <Select
                showSearch={true}
                mode='multiple'
                style={{ width: '100%' }}
                allowClear
                value={formData.sec_games ?? []}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={utils.sec_games.map(x => ({
                  id: x.id.toString(),
                  value: x.name,
                  label: x.name,
                }))}
                onChange={v => updateForm('sec_games', v)}
              />
            </div>
            <Seperator></Seperator>

            <div style={{ marginBottom: 20 }}>
              <label>Write something about yourself and your work. </label>
              <TextArea
                value={formData.headline ?? ''}
                onChange={e => updateForm('headline', e.target.value)}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <label>Add your Youtube video link below:</label>
              <Input
                type='url'
                value={formData.youtube_video_url ?? ''}
                onChange={e => updateForm('youtube_video_url', e.target.value)}
              />
            </div>

            <div style={{ marginBottom: 20, marginTop: 10 }}>
              {!!videoFile ? (
                <video width='315' controls>
                  <source src={URL.createObjectURL(videoFile)} />
                </video>
              ) : !!formData?.video_url ? (
                <video width='315' controls>
                  <source src={formData.video_url} />
                </video>
              ) : (
                <></>
              )}
              {percent !== undefined && (
                <div style={{ margin: '20px 10px' }}>
                  <progress
                    value={percent}
                    max={100}
                    style={{ width: '100%' }}
                  />
                </div>
              )}
              <input
                type='file'
                onChange={e =>
                  e.target.files &&
                  e.target.files.length > 0 &&
                  setVideoFile(e.target.files?.[0])
                }
                id='video-upload'
                accept='video/*'
                style={{ display: 'none' }}
              />
              <div className='d-flex mt-5'>
                <Button
                  type='primary'
                  onClick={() =>
                    document.getElementById('video-upload')?.click()
                  }
                  className='mr-10'
                >
                  Upload video
                </Button>
                <Button
                  type='primary'
                  onClick={() => {
                    setVideoFile(undefined);
                    updateForm('video_url', null);
                  }}
                  className='ml-10'
                >
                  Remove video
                </Button>
              </div>
            </div>

            <div style={{ marginBottom: 20 }}>
              <label>LinkedIn URL:</label>
              <Input
                type='url'
                value={formData.linkedin_url ?? ''}
                onChange={e => updateForm('linkedin_url', e.target.value)}
              />
            </div>
            <Seperator></Seperator>
            <div
              style={{
                marginBottom: '10px',
                marginTop: '20px',
                color: 'white !important',
              }}
            >
              <Checkbox
                checked={formData.type === 'OWN'}
                onChange={e =>
                  updateForm('type', e.target.checked ? 'OWN' : null)
                }
              >
                <span style={{ color: 'white' }}>
                  Check if you have a startup company and are looking for OU
                  alumni cofounders:
                </span>
              </Checkbox>
            </div>
            <div style={{ marginBottom: '20px', color: 'white !important' }}>
              <Checkbox
                checked={formData.type === 'JOIN'}
                onChange={e =>
                  updateForm('type', e.target.checked ? 'JOIN' : null)
                }
              >
                <span style={{ color: 'white' }}>
                  Check if you are interested in joining a startup as an OU
                  alumni cofounder:
                </span>
              </Checkbox>
            </div>
            {formData.type !== null && (
              <div style={{ marginBottom: '20px' }}>
                <label style={{ marginBottom: '20px' }}>
                  {formData.type === 'OWN'
                    ? 'Select the roles / skills you are looking to fill: (please include something about your startup in your intro. Boomer Sooner!'
                    : 'Select the roles you might fit into well as a cofounder:'}
                </label>
                <Select
                  mode='multiple'
                  style={{ width: '100%' }}
                  allowClear
                  value={formData.skills_id?.map(x => x.toString())}
                  options={utils.skills?.map(x => ({
                    id: x.id.toString(),
                    value: x.id.toString(),
                    label: x.name,
                  }))}
                  onChange={v =>
                    updateForm(
                      'skills_id',
                      v.map(x => Number(x)),
                    )
                  }
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(inputValue.toLowerCase())
                  }
                />
              </div>
            )}
            <Seperator></Seperator>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginTop: 30,
              }}
            >
              <div>
                <Avatar
                  shape='circle'
                  size={90}
                  src={formData.profile_pic}
                  icon={<UserOutlined />}
                  style={{ background: '#3e3e3e' }}
                />
              </div>
              <div>
                <ImgCrop>
                  <Upload
                    customRequest={(e: any) =>
                      handleFileChange('profile_pic', e.file)
                    }
                    fileList={[]}
                    listType='picture-card'
                    accept='image/x-png,image/gif,image/jpeg'
                  >
                    <label htmlFor='upload-avatar' className='upload-button'>
                      <CameraIcon />
                      <span
                        className='upload-button__text'
                        style={{ color: 'white' }}
                      >
                        Profile photo:
                      </span>
                    </label>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginBottom: 65,
                marginTop: 35,
              }}
            >
              <div>
                <Avatar
                  shape='circle'
                  size={90}
                  src={formData.profile_url}
                  icon={<AppstoreOutlined />}
                  style={{ background: '#3e3e3e' }}
                />
              </div>
              <div>
                <ImgCrop>
                  <Upload
                    customRequest={(e: any) =>
                      handleFileChange('profile_url', e.file)
                    }
                    fileList={[]}
                    listType='picture-card'
                    accept='image/x-png,image/gif,image/jpeg'
                  >
                    <label htmlFor='upload-avatar' className='upload-button'>
                      <CameraIcon />
                      <span
                        className='upload-button__text'
                        style={{ color: 'white' }}
                      >
                        Background image:
                      </span>
                    </label>
                  </Upload>
                </ImgCrop>
              </div>
            </div>

            {loading ? (
              <div
                style={{ position: 'fixed', bottom: '30px', width: '315px' }}
              >
                <img src={Loading} alt='' style={{ width: '315px' }} />
              </div>
            ) : (
              <div
                style={{ position: 'fixed', bottom: '30px', width: '315px' }}
              >
                <SignFormButton disabled={IsInvalid} type='submit'>
                  Save yOUr Changes
                </SignFormButton>
              </div>
            )}
          </SignFormBase>
        )}
      </SignFormWrapper>
      <FooterCompound />
    </>
  );
};

export default ProfilePage;
