import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Logo from '../../components/Header/Logo'; // Adjusted path

/*---> Component <---*/
const FooterRow = ({ children }: PropsType) => {
  return (
    <Wrapper>
      <TextContainer>{children}</TextContainer>
      <StyledLogoContainer><Logo /></StyledLogoContainer>
    </Wrapper>
  );
};

/*---> Styles <---*/
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; // Allows items to wrap in smaller screens

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  // Add additional styling here
`;

const StyledLogoContainer = styled.div`
  // Add styling for the Logo container here
  @media (max-width: 768px) {
    order: -1; // Moves the logo above the text on mobile screens
  }
`;

/*---> Interfaces <---*/
interface PropsType {
  children?: ReactNode;
}

export default FooterRow;
