import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import UserAvatar from './UserAvatar';
import { IUserProfile } from '../../lib/entities';
import styled from 'styled-components';

const UserCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 32px 15px 15px;
  margin: 20px 10px;
  min-width: 280px;
  max-width: 280px;
  position: relative;
  box-shadow: rgb(0 0 0 / 30%) 0px 4px 4px 2px;
  border: 1px solid rgb(255 255 255 / 50%);
  background-color: #8d1e20;
  background: url(/images/misc/red-circuit-bg.png);
  background-size: cover;
  cursor: pointer;

  & .video-wrap {
    margin-top: 1rem;
  }
`;

const UserName = styled.div`
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 16px;
  font-family: 'Allura';
  font-size: 32px;
  text-align: center;
`;

const FeatureWrapper = styled.div`
  & .title {
    color: #b1b1b1;
    font-size: 10px;
    margin-top: 12px;
  }
  & .about-me {
    margin-top: 8px;
    font-size: 14px;
  }
`;

const FeatureList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FeatureItem = styled.div`
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 4px 8px;
  white-space: nowrap;
  margin-right: 6px;
  margin-top: 4px;
  &.active {
    background-color: #fff;
    color: #982022;
  }
`;

const UserCard = ({ user, onClick }: PropsType) => {
  const profile = useSelector((state: RootState) => state.auth.profile);

  return (
    <UserCardContainer>
      <UserAvatar
        src={user.profile_pic || ''}
        size={160}
        onClick={() => onClick(user)}
      />
      <UserName onClick={() => onClick(user)}>
        {`${user.first_name} ${user.last_name} ${
          user.nickname ? `(${user.nickname})` : ''
        }`}
      </UserName>
      <div style={{ width: '100%', flex: 1 }}>
        <FeatureWrapper>
          <FeatureList>
            {user.job_titles?.map(x => (
              <FeatureItem
                key={x.id}
                className={
                  profile?.job_titles?.some(y => y.id === x.id) ? 'active' : ''
                }
              >
                {x.name}
              </FeatureItem>
            ))}
            {user.degrees?.map(x => (
              <FeatureItem
                key={x.id}
                className={
                  profile?.degrees?.some(y => y.id === x.id) ? 'active' : ''
                }
                style={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {x.name}
              </FeatureItem>
            ))}
          </FeatureList>
        </FeatureWrapper>
        {user?.headline && (
          <FeatureWrapper>
            <div className='title'>About this Sooner:</div>
            <div className='about-me'>
              {user.headline.slice(0, 200)}
              {user.headline.length > 200 ? '...' : ''}
            </div>
          </FeatureWrapper>
        )}
      </div>
      <div style={{ position: 'absolute', top: 10, right: 10, fontSize: 28 }}>
        {`${Math.round((user as any).share_cnt ?? 0)}`}
      </div>
    </UserCardContainer>
  );
};

interface PropsType {
  user: IUserProfile;
  onClick: (d: any) => void;
}

export default UserCard;
