import { Dispatch } from 'redux';
import { sendRequest } from '../../lib/api';
import { IUtilAction } from './util.type';

const setUtils = (utils: IUtilAction): IUtilAction => utils;

export const loadDegrees = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/degrees');

    if (res.status) {
      dispatch(setUtils({ type: 'UTIL/SET_DEGREES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadGradLevels = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/grad_levels');

    if (res.status) {
      dispatch(setUtils({ type: 'UTIL/SET_GRAD_LEVELS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadIbcCompanies = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/ibc_companies');

    if (res.status) {
      dispatch(setUtils({ type: 'UTIL/SET_IBC_COMPANIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadStudentOrgs = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/student_orgs');

    if (res.status) {
      dispatch(setUtils({ type: 'UTIL/SET_STUDENT_ORGS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadAssociations = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/associations');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_ASSOCIATIONS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadIndustries = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/industries');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_INDUSTRIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadHobbies = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/hobbies');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_HOBBIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadJobTitles = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/job_titles');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_JOB_TITLES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadMilitaryBranches = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/military_branches');

    if (res.status) {
      dispatch(
        setUtils({ type: 'UTILS/SET_MILITARY_BRANCHES', payload: res.data }),
      );
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadSchools = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/schools');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_SCHOOLS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadCities = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/cities');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_CITIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadCountries = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/countries');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_COUNTRIES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadSkills = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/skills');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_SKILLS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadOrgJobs = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/org_jobs');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_ORG_JOBS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadAthletes = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/athletes');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_ATHLETES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadRomances = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/romances');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_ROMANCES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadAlumniClubs = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/alumni_clubs');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_ALUMNI_CLUBS', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadSportsWatches = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/sports_watches');

    if (res.status) {
      dispatch(
        setUtils({ type: 'UTILS/SET_SPORTS_WATCHES', payload: res.data }),
      );
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};

export const loadSecGames = () => {
  return async (dispatch: Dispatch<any>) => {
    const res = await sendRequest({}, 'GET', 'api/sec_games');

    if (res.status) {
      dispatch(setUtils({ type: 'UTILS/SET_SEC_GAMES', payload: res.data }));
      return true;
    } else {
      return res.data?.message ?? 'Failed to load';
    }
  };
};
