import FooterWrapper from '../components/Footer/FooterWrapper';
import FooterRow from '../components/Footer/FooterRow';

/*---> Component <---*/
const FooterCompound = () => {
  return (
    <FooterWrapper>
      <FooterRow>
        <ul>
          <li>
            "Don't let yesterday take up too much of today." - Will Rogers, OU
            alumni{' '}
          </li>
          <li>
            "Success is not final, failure is not fatal: It is the courage to
            continue that counts." - Barry Switzer
          </li>
          <li>
            "Hard work beats talent when talent doesn't work hard." - Bob Stoops
          </li>
          <li>
            "Don't Keep OU A Secret!" - David Boren
          </li>
        </ul>
      </FooterRow>
    </FooterWrapper>
  );
};

export default FooterCompound;
