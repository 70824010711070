import { useState } from 'react';
import HeaderWrapper from '../../components/Header/HeaderWrapper';
import Navbar from '../../components/Header/Navbar';
import Logo from '../../components/Header/Logo';
import SignFormWrapper from '../../components/SignForm/SignFormWrapper';
import SignFormBase from '../../components/SignForm/SignFormBase';
import SignFormTitle from '../../components/SignForm/SignFormTitle';
import SignFormError from '../../components/SignForm/SignFormError';
import SignFormWInput from '../../components/SignForm/SignFormInput';
import SignFormButton from '../../components/SignForm/SignFormButton';
import { sendRequest } from '../../lib/api';

const ForgotPasswordPage = () => {
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setDisabled(true);
    const res = await sendRequest(
      { email: emailAddress },
      'POST',
      'api/auth/forgot-password',
    );
    setDisabled(false);
    if (res.status) {
      window.alert(
        'Recovery email has been sent successfully! Please check your inbox!',
      );
    } else {
      setError(res.data?.message ?? 'Error');
    }
  };

  return (
    <>
      <HeaderWrapper>
        <Navbar hideNav={true}>
          <Logo />
        </Navbar>
        <SignFormWrapper>
          <SignFormBase onSubmit={handleSubmit} method='POST'>
            <SignFormTitle>
              Boomer Sooner! Please enter your email address. We will send you
              recovery password email.
            </SignFormTitle>
            {error ? <SignFormError>{error}</SignFormError> : null}
            <SignFormWInput
              type='text'
              placeholder='Your Email Address:'
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
            />
            <SignFormButton disabled={disabled} type='submit'>
              Request to reset password
            </SignFormButton>
          </SignFormBase>
        </SignFormWrapper>
      </HeaderWrapper>
    </>
  );
};

export default ForgotPasswordPage;
